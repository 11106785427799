import $ from "./../vendor/jquery/dist/jquery";
import {sum, square, MyClass} from "./import";
import createAccordion from "./accordion";
import "./../vendor/mmenu.min";
import slick from "./../vendor/slick/rdslick.js";
import {navigation} from "./navigation";
import {slider} from "./slider";
import {footerLinkToggle} from "./footer";
import createMlMenu from "./multi-level-menu";

$(document).ready(init);

function init(){
	$.fn.slick = slick;
	runImportedFunctions();
	setupAccordionLists();
}

function runImportedFunctions(){
	
	navigation();
	slider();
	footerLinkToggle();
	setupMenu();

	$(".portlet-faq").on("click", function(e) {
		$(".portlet-faq").removeClass("isActive");
		$(e.target).closest(".portlet-faq").addClass("isActive");
	});

	$(".about-us, .bill, .account").find('.page-sidebar ul li').each(function() {
		$(this).addClass('about-us-portlet');
	});

	// $(".bill").find('.page-sidebar ul li').each(function() {
	// 	$(this).addClass('about-us-portlet');
	// });

	// $(".account").find('.page-sidebar ul li').each(function() {
	// 	$(this).addClass('about-us-portlet');
	// });


	$( ".about-us-portlet" ).prepend( '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrows-bold-right"></use></svg>' );



}

function setupAccordionLists(){
	const accordionLists = $(".js-accordion-list");
	accordionLists.each(function(i, el){
		el.accordionController = createAccordion($(el));
	});
}

function setupMenu(){
	const menu = createMlMenu('.js-menu-test', {
		side: 'right',
		clone: false,
		breadcrumbSpacer: '<div class="ml-menu__breadcrumb-space"><svg><use xlink:href="#breadcrumb-spacer" /></svg></div>',
		subnavLinkHtml: '<svg><use xlink:href=#menu-dots" /></svg>',
		backButtonHtml: '<svg><use xlink:href="#menu-back" /></svg>',
		closeButtonHtml: '<svg><use xlink:href="#close" /></svg>'
	});

	const showMenu = document.querySelector('.js-menu-show');

	if(showMenu){
		showMenu.addEventListener('click', menu.slideInController.show);
	}
}